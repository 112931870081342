import makeStyles from '@mui/styles/makeStyles'

export const useBlackButtonStyle = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
      background: theme.palette.grey[200],
    },
  },
}))
