import React, { FC, useMemo, useState, useRef, useCallback, JSX } from 'react'
import { Box, Fade, IconButton, Typography, Checkbox } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import clsx from 'clsx'
import { Apps, CalendarTodayOutlined, Edit } from '@mui/icons-material'
import { Draggable } from 'react-beautiful-dnd'
import { DayPickerModal } from '../DayPickerModal'
import { isNumber } from 'lodash'
import { LocalSurvey } from '../treatmentSchemaSchedule.types'

const useStyles = makeStyles((theme) => ({
  container: {
    borderWidth: 2,
    borderColor: theme.palette.grey[300],
    background: 'white',
    '&:hover': {
      background: theme.palette.grey[50],
    },
  },
  error: {
    borderColor: theme.palette.error.light,
  },
  dayError: {
    color: theme.palette.error.light,
  },
  isDragging: {
    background: theme.palette.grey[50],
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  detailsContainer: {
    position: 'relative',
    borderTop: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
  },
  editDetails: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

interface Props {
  index: number
  id: string
  survey: LocalSurvey
  isEditable: boolean
  selected: boolean
  handleSelect: () => void
  onDayChanged: (id: string, day?: number) => void
}

export const TreatmentSchemaSurveyDraggableListItem: FC<
  React.PropsWithChildren<Props>
> = ({
  survey,
  index,
  id,
  isEditable,
  selected,
  handleSelect,
  onDayChanged: onDayChangedProp,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const anchorEl = useRef(null)
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false)

  const typeText = survey.surveySchema.title

  const toggleDayPicker = useCallback(() => {
    setIsDayPickerOpen((wasOpen) => !wasOpen)
  }, [])

  const closeDayPicker = useCallback(() => {
    setIsDayPickerOpen(false)
  }, [])

  const onDayChanged = useCallback(
    (newDay?: number) => {
      onDayChangedProp(id, newDay)
      setIsDayPickerOpen(false)
    },
    [onDayChangedProp, id]
  )

  const DayChooserComponent = useMemo<JSX.Element>(() => {
    const dayText = isNumber(survey.customDays)
      ? t('common:formattedDay', {
          day: survey.customDays + 1,
        })
      : null
    return (
      <Box
        width={110}
        display="flex"
        alignItems={'center'}
        justifyContent={'flex-start'}
        mr={1}
        ref={anchorEl}
      >
        {!!dayText && (
          <Typography
            variant="subtitle1"
            ml={1}
            className={survey.dayError ? classes.dayError : ''}
          >
            {dayText}
          </Typography>
        )}
        {isEditable && (
          <IconButton size="large" onClick={toggleDayPicker}>
            {!!dayText ? index !== 0 && <Edit /> : <CalendarTodayOutlined />}
          </IconButton>
        )}
      </Box>
    )
  }, [
    classes.dayError,
    index,
    isEditable,
    survey.customDays,
    survey.dayError,
    t,
    toggleDayPicker,
  ])

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Fade
          in={true}
          timeout={750}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Box display="flex" flexDirection="row">
            <Box
              className={clsx(classes.container, {
                [classes.isDragging]: snapshot.isDragging,
                [classes.error]: !!survey.dayError,
              })}
              mb={1}
              border={1}
              borderRadius={1.3}
              flex={1}
            >
              <Box id={`arrow-row-${id}`} display="flex" alignItems="center">
                <IconButton
                  size="large"
                  {...provided.dragHandleProps}
                  disabled={!isEditable}
                >
                  <Apps />
                </IconButton>
                {isEditable && (
                  <Checkbox
                    checked={selected}
                    onChange={handleSelect}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                )}
                {DayChooserComponent}
                <Box flex={1}>
                  <Typography variant="italic">{typeText}</Typography>
                </Box>
              </Box>
            </Box>
            <DayPickerModal
              open={isDayPickerOpen}
              onClose={closeDayPicker}
              anchorEl={anchorEl?.current}
              selectedDay={survey.customDays}
              onDayChanged={onDayChanged}
              isDayDeletable={true}
            />
          </Box>
        </Fade>
      )}
    </Draggable>
  )
}
