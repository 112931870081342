import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  BetmenList,
  BetmenListBody,
  BetmenListHeader,
  BetmenListHeaderCell,
} from '../../../../common/components'
import { PatientListActions } from './PatientListActions'
import { PatientListItem } from './PatientListItem'
import { UsePatientsListReturn } from '../../../../common/hooks/usePatientsList'
import { Box } from '@mui/material'
import { PatientListItemType } from '../types/patientList.types'
import { CHECKBOX_WIDTH } from './patients.constants'
import { useTranslation } from '../../../../common/hooks'
import { PatientListFilter } from '../types/patientFilter.types'
import { deleteDoctorsOwnPatients as BulkDismissResult } from '../../../../models/graphqlTypes'

export interface PatientListProps
  extends Pick<
    UsePatientsListReturn,
    'data' | 'isLoading' | 'hasMore' | 'fetchMoreLoading' | 'refetch'
  > {
  fetchMore: (offset: number) => void
  filterInput: PatientListFilter
  setFilterInput: (filter: PatientListFilter) => void
  setFilterPanelOpen: Dispatch<SetStateAction<boolean>>
  isFilterPanelOpen: boolean
}

const PatientList: React.FC<React.PropsWithChildren<PatientListProps>> = ({
  data,
  isLoading,
  hasMore,
  fetchMore,
  fetchMoreLoading,
  refetch,
  filterInput,
  setFilterInput,
  setFilterPanelOpen,
  isFilterPanelOpen,
}) => {
  const { t } = useTranslation()

  const [selectedPatients, setSelectedPatients] = useState<
    PatientListItemType[]
  >([])

  const [deletedPatients, setDeletedPatients] =
    useState<BulkDismissResult | null>(null)

  const fetchMorePatients = useCallback(() => {
    const offset = data.length
    fetchMore(offset)
  }, [data.length, fetchMore])

  useEffect(() => {
    setSelectedPatients([])
  }, [filterInput])

  return (
    <BetmenList
      loading={isLoading && !fetchMoreLoading}
      fetchMore={fetchMorePatients}
      fetchMoreLoading={fetchMoreLoading}
      dataSource={data}
      hasMoreItem={hasMore}
      gridColumnSizes={[1, 4, 2, 3, 2]}
      filters={
        <PatientListActions
          isFilterPanelOpen={isFilterPanelOpen}
          data={data}
          selectedPatients={selectedPatients}
          setSelectedPatients={setSelectedPatients}
          refetch={refetch}
          filterInput={filterInput}
          setFilterInput={setFilterInput}
          setFilterPanelOpen={setFilterPanelOpen}
          deletedPatients={deletedPatients}
          setDeletedPatients={setDeletedPatients}
        />
      }
      header={
        <BetmenListHeader>
          <Box width={data[0]?.isActive ? CHECKBOX_WIDTH : 0} />
          <BetmenListHeaderCell>
            {t('patients:namePlaceholder')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:taj')}</BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:birthdate')}</BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:status')}</BetmenListHeaderCell>
        </BetmenListHeader>
      }
    >
      <BetmenListBody>
        {data.map((patient, i) => (
          <PatientListItem
            key={`${patient.id}_${i}`}
            patient={patient}
            selectedPatients={selectedPatients}
            setSelectedPatients={setSelectedPatients}
          />
        ))}
      </BetmenListBody>
    </BetmenList>
  )
}

export { PatientList }
