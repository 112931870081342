import { countBy, isObject } from 'lodash'
import { isEmpty } from './isEmpty'

export const hasActiveFilter = (filterData: object): boolean =>
  Object.values(filterData).some((value) => {
    if (isObject(value)) {
      return Object.values(value).some((v) => !isEmpty(v))
    } else {
      return !isEmpty(value)
    }
  })

export const numberOfFilters = (filterData: object): number =>
  countBy(
    Object.values(filterData).map((value) => {
      if (isObject(value)) {
        return Object.values(value).some((v) => !isEmpty(v))
      } else {
        return !isEmpty(value)
      }
    }),
    Boolean
  ).true
