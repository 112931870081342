import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
} from '@mui/material'

import { BnoCodeSelector } from '../../../../common/components/selectors/BnoCodeSelector'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { AGE_MAX, AGE_MIN } from './patients.constants'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../../common/components'
import { MultiSelector } from '../../../../common/components/selectors/MultiSelector'
import { useCities } from '../../../../common/hooks/useCities'
import { InstitutionSelector } from '../../../../common/components/selectors/InstitutionSelector'
import { BoxWithLabel } from '../../../../common/components/BoxWithLabel'
import { useAppointmentTypes } from '../../../../common/hooks/useAppointmentTypes'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation, useUserType } from '../../../../common/hooks'
import { hasActiveFilter } from '../../../../utils/hasActiveFilter'
import {
  AppointmentStatus,
  Gender,
  UserType,
} from '../../../../models/graphqlTypes'
import { FILTER_PANEL_WIDTH } from '../../../../config'
import { ResetOneFilterButton } from '../../../../common/components/FilterPanelButtons/ResetOneFilterButton'
import {
  PatientListFilter,
  PatientListFiltersForm,
  UsedAppointmentStatus,
} from '../types/patientFilter.types'
import { useAssistantDoctors } from '../../../assistant/hooks/useAssistantDoctors'
import { OpenFilterPanelButton } from '../../../../common/components/FilterPanelButtons/OpenFilterPanelButton'

interface PatientListFiltersProps {
  isFilterPanelOpen: boolean
  setFilterPanelOpen: Dispatch<SetStateAction<boolean>>
  filterInput: PatientListFilter
  setFilterInput: Dispatch<SetStateAction<PatientListFilter>>
}
export const PatientListFilters: React.FC<
  React.PropsWithChildren<PatientListFiltersProps>
> = ({
  isFilterPanelOpen,
  setFilterPanelOpen,
  filterInput,
  setFilterInput,
}) => {
  const { t } = useTranslation('patients')
  const selectedLanguage = useSelectedLanguage()
  const isAssistant = useUserType() === UserType.Assistant

  const { data: cities, loading: isCitiesLoading } = useCities()
  const { appointmentTypes, loading: isAppointmentsLoading } =
    useAppointmentTypes()

  const { doctors, loading: isDoctorsLoading } = useAssistantDoctors()

  const formMethods = useForm<PatientListFiltersForm>({
    defaultValues: {
      bnoCode: {
        includes: [],
        excludes: [],
      },
      appointments: [],
      gender: null,
      age: [AGE_MIN, AGE_MAX],
      appointmentDateRange: {
        start: null,
        stop: null,
      },
      cities: [],
      institutions: [],
      doctors: [],
    },
  })

  const { watch, reset, control, setValue } = formMethods

  const clearFilters = () => {
    setFilterInput({})
    reset()
  }

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      setFilterInput((prevInput: PatientListFilter) => {
        return {
          ...prevInput,
          bnoCode: {
            includes: value.bnoCode?.includes?.map((code) => code?.id ?? ''),
            excludes: value.bnoCode?.excludes?.map((code) => code?.id ?? ''),
          },
          appointmentInfoIds: value.appointments?.map(
            (appointment) => appointment?.id ?? ''
          ),
          gender: value.gender || undefined,
          age: {
            min: value.age?.[0] === AGE_MIN ? undefined : value.age?.[0],
            max: value.age?.[1] === AGE_MAX ? undefined : value.age?.[1],
          },
          cities: value.cities as string[],
          appointmentDateRange: {
            start: value.appointmentDateRange?.start
              ? new Date(value.appointmentDateRange?.start)
              : null,
            stop: value.appointmentDateRange?.stop
              ? new Date(value.appointmentDateRange?.stop)
              : null,
          },
          appointmentStatuses: Object.entries(value.appointmentStatuses ?? {})
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, isChecked]) => isChecked)
            .map(([status]) => status as AppointmentStatus),
          institutionIds:
            value.institutions?.map((institution) => institution?.id ?? '') ??
            [],
          doctorIds:
            value.doctors?.map((doctor) => doctor?.doctor?.id ?? '') ?? [],
        }
      })
    })
    return () => unsubscribe()
  }, [setFilterInput, watch])

  return (
    <Collapse in={isFilterPanelOpen} orientation="horizontal">
      <FormProvider {...formMethods}>
        <Stack
          sx={{
            width: FILTER_PANEL_WIDTH,
            height: '100%',
            backgroundColor: 'white',
          }}
          py={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            pl={2}
            pr={1}
          >
            <OpenFilterPanelButton
              isFilterPanelOpen={isFilterPanelOpen}
              setFilterPanelOpen={setFilterPanelOpen}
              filterInput={filterInput}
            />

            {hasActiveFilter(filterInput) && (
              <Button
                size="small"
                color="primary"
                variant="text"
                onClick={clearFilters}
                sx={{ marginLeft: 'auto', paddingX: '4px' }}
              >
                {t('filters.reset')}
              </Button>
            )}
          </Stack>

          <Stack px={1} sx={{ gap: '16px' }}>
            {isAssistant && (
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <Typography variant="body2">{t('filters.doctor')}</Typography>
                  {(filterInput.doctorIds?.length ?? 0) > 0 && (
                    <ResetOneFilterButton
                      onClick={() => setValue('doctors', [])}
                    />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <MultiSelector
                    name="doctors"
                    isOptionsLoading={isDoctorsLoading}
                    options={doctors || []}
                    getOptionLabel={(option) =>
                      t('common:formattedNameFull', {
                        title: option.doctor.title,
                        firstName: option.doctor.firstName,
                        lastName: option.doctor.lastName,
                      })
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.bno.title')}
                </Typography>
                {((filterInput.bnoCode?.includes?.length ?? 0) > 0 ||
                  (filterInput.bnoCode?.excludes?.length ?? 0) > 0) && (
                  <ResetOneFilterButton
                    onClick={() => {
                      setValue('bnoCode.includes', [])
                      setValue('bnoCode.excludes', [])
                    }}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <BnoCodeSelector
                  name="bnoCode.includes"
                  label={t('filters.bno.includes')}
                  exclude={filterInput.bnoCode?.excludes ?? []}
                />
                <BnoCodeSelector
                  name="bnoCode.excludes"
                  label={t('filters.bno.excludes')}
                  exclude={filterInput.bnoCode?.includes ?? []}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.appointment')}
                </Typography>
                {(filterInput.appointmentInfoIds?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => setValue('appointments', [])}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <MultiSelector
                  name="appointments"
                  isOptionsLoading={isAppointmentsLoading}
                  options={appointmentTypes}
                  getOptionLabel={(option) =>
                    option?.doctorTitle[selectedLanguage] ?? ''
                  }
                />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.gender.title')}
                </Typography>
                {filterInput.gender && (
                  <ResetOneFilterButton
                    onClick={() => setValue('gender', null)}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" alignItems="center">
                  <Controller
                    render={({ field }) => (
                      <RadioGroup row aria-label="gender" {...field}>
                        <FormControlLabel
                          value={Gender.MALE}
                          control={<Radio />}
                          label={t('filters.gender.male')}
                        />
                        <FormControlLabel
                          value={Gender.FEMALE}
                          control={<Radio />}
                          label={t('filters.gender.female')}
                          sx={{ marginLeft: '50px' }}
                        />
                      </RadioGroup>
                    )}
                    name="gender"
                    control={control}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.age.title')}
                </Typography>
                {(filterInput.age?.min || filterInput.age?.max) && (
                  <ResetOneFilterButton
                    onClick={() => setValue('age', [AGE_MIN, AGE_MAX])}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ px: 1 }}>
                  <Controller
                    name="age"
                    control={control}
                    defaultValue={[AGE_MIN, AGE_MAX]}
                    render={({ field }) => (
                      <Slider
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value)
                        }}
                        max={AGE_MAX}
                        marks={[
                          { value: AGE_MIN, label: `${AGE_MIN}` },
                          {
                            value: AGE_MAX,
                            label: ` ${AGE_MAX} ${t('filters.age.years')}`,
                          },
                        ]}
                        valueLabelDisplay="auto"
                      />
                    )}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">{t('filters.city')}</Typography>
                {(filterInput.cities?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => setValue('cities', [])}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <MultiSelector
                  name="cities"
                  isOptionsLoading={isCitiesLoading}
                  options={cities}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.treatment.title')}
                </Typography>
                {(!!filterInput.appointmentDateRange?.start ||
                  !!filterInput.appointmentDateRange?.stop ||
                  (filterInput.appointmentStatuses?.length ?? 0) > 0) && (
                  <ResetOneFilterButton
                    onClick={() => {
                      setValue('appointmentDateRange.start', null)
                      setValue('appointmentDateRange.stop', null)
                      setValue('appointmentStatuses', {})
                    }}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <BoxWithLabel label={t('filters.treatment.date')}>
                  <Stack direction="row" gap={1.5} alignItems="center">
                    <Controller
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          maxDate={watch('appointmentDateRange.stop')}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      )}
                      name="appointmentDateRange.start"
                      control={control}
                    />
                    <Typography variant="body1">-</Typography>
                    <Controller
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          minDate={watch('appointmentDateRange.start')}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      )}
                      name="appointmentDateRange.stop"
                      control={control}
                    />
                  </Stack>
                </BoxWithLabel>

                <BoxWithLabel label={t('filters.treatment.status')}>
                  <Stack direction="column">
                    {Object.values(UsedAppointmentStatus).map((status) => (
                      <Controller
                        key={status}
                        render={({ field }) => (
                          <FormControlLabel
                            label={t(`appointment:status.${status}`)}
                            control={
                              <Checkbox
                                {...field}
                                checked={
                                  !!watch(`appointmentStatuses.${status}`)
                                }
                              />
                            }
                          />
                        )}
                        name={`appointmentStatuses.${status}`}
                        control={control}
                      />
                    ))}
                  </Stack>
                </BoxWithLabel>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.institution')}
                </Typography>
                {(filterInput.institutionIds?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => setValue('institutions', [])}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <InstitutionSelector name="institutions" multiple={true} />
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </FormProvider>
    </Collapse>
  )
}
