import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import { Box, GridSize, SortDirection, Stack, Typography } from '@mui/material'
import { isFunction } from 'lodash'
import React, { createContext, FC, useContext, useState } from 'react'
import { Loading } from '../Loading'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { useColorPalette } from '../../hooks/helper/useColor'
import { useBetmenPage } from '../BetmenPage'

interface BetmenListContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource: any[]
  loading?: boolean
  hasMoreItem?: boolean
  fetchMore?: (offset: number) => void
  sort?: (name: string | number | undefined, dir: SortDirection) => void
  lastSortedColumnName?: string | number | undefined
  gridColumnSizes?: GridSize[]
}

export const BetmenListContext = createContext<BetmenListContextProps>({
  dataSource: [],
  loading: false,
  hasMoreItem: true,
  gridColumnSizes: [],
})

export const useBetmenList = (): BetmenListContextProps =>
  useContext(BetmenListContext)

interface BetmenListProps
  extends Omit<BetmenListContextProps, 'lastSortedColumnName'> {
  filters?: React.ReactNode
  header?: React.ReactNode
  notFoundMessage?: string
  fetchMoreLoading?: boolean
  extraHeaderStyle?: React.CSSProperties
}

export const BetmenList: FC<React.PropsWithChildren<BetmenListProps>> = ({
  children,
  dataSource,
  loading,
  hasMoreItem = true,
  filters,
  header,
  notFoundMessage,
  gridColumnSizes,
  fetchMoreLoading,
  fetchMore,
  sort,
  extraHeaderStyle,
}) => {
  const { t } = useTranslation()
  const { scrollPositionY, basePadding } = useBetmenPage()
  const [lastSortedColumnName, setLastSortedColumnName] = useState<
    string | number | undefined
  >(undefined)
  const colorPalette = useColorPalette()

  const headerStyle =
    scrollPositionY > 0
      ? {
          borderBottom: `1px solid ${colorPalette.grey[300]}`,
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          boxShadow: 3,
        }
      : {}

  const emitSortAndSetLastSortedColumn = (
    name: string | number | undefined,
    dir: SortDirection
  ) => {
    setLastSortedColumnName(name)
    isFunction(sort) && sort(name, dir)
  }

  const renderChildren = (): React.ReactNode | React.ReactNode[] => {
    if (loading) {
      return (
        <Box display="flex" height="100%" alignItems="center">
          <Loading />
        </Box>
      )
    }
    if (!dataSource.length) {
      return (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mb={8}
        >
          <ListAltOutlinedIcon
            sx={{ width: '100px', height: '100px', mb: 2 }}
          />
          <Typography variant="h4">
            {notFoundMessage || t('common:notFound')}
          </Typography>
        </Box>
      )
    }
    return children
  }

  return (
    <BetmenListContext.Provider
      value={{
        dataSource,
        loading,
        hasMoreItem,
        lastSortedColumnName,
        gridColumnSizes,
        fetchMore,
        sort: emitSortAndSetLastSortedColumn,
      }}
    >
      <Stack position="relative">
        <Stack
          pt={1}
          position={'sticky'}
          top={0}
          zIndex={10}
          sx={{
            background: colorPalette.grey[200],
            ...headerStyle,
            ...extraHeaderStyle,
          }}
        >
          <Stack px={basePadding || {}}>{filters}</Stack>
          <Box>{header}</Box>
        </Stack>
        <Stack px={basePadding || {}}>{renderChildren()}</Stack>
        {fetchMoreLoading && (
          <Box display="flex" height="100%" alignItems="center">
            <Loading />
          </Box>
        )}
      </Stack>
    </BetmenListContext.Provider>
  )
}
