import { Button, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from '../../hooks'
import {
  hasActiveFilter,
  numberOfFilters,
} from '../../../utils/hasActiveFilter'
import TuneIcon from '@mui/icons-material/Tune'
import { useBlackButtonStyle } from '../../../utils/useBlackButtonStyle'

interface Props {
  filterInput: object
  isFilterPanelOpen: boolean
  setFilterPanelOpen: Dispatch<SetStateAction<boolean>>
}

export const OpenFilterPanelButton: React.FC<
  React.PropsWithChildren<Props>
> = ({ filterInput, isFilterPanelOpen, setFilterPanelOpen }) => {
  const { t } = useTranslation()
  const classes = useBlackButtonStyle()

  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={() => setFilterPanelOpen((prevInput) => !prevInput)}
      sx={{ paddingX: '16px' }}
      startIcon={<TuneIcon />}
      classes={{ textPrimary: isFilterPanelOpen ? classes.textPrimary : '' }}
    >
      <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
        {t('patients:filters.title')}

        {hasActiveFilter(filterInput) && `(${numberOfFilters(filterInput)})`}
      </Typography>
    </Button>
  )
}
