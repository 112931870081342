import React, { FC, useState } from 'react'
import { BetmenPage } from '../BetmenPage'
import { useEesztRedirectListener } from '../../hooks/eeszt/useEesztRedirectListener'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { DoctorAppointmentsOnDashboard } from './DoctorAppointmentsOnDashboard'
import { DoctorSurveysOnDashboard } from './DoctorSurveysOnDashboard'
import { DoctorSurveyFilters } from './DoctorSurveyFilters'
import { SurveyFilterForAssistant } from '../../../models/graphqlTypes'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import { useDashboardPaths } from './hooks/useDashboardPaths'
import { DashboardTab } from './dashboard.types'
import last from 'lodash/last'

export const Dashboard: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  const [isFilterPanelOpen, setFilterPanelOpen] = useState(true)
  const [filterInput, setFilterInput] = useState<SurveyFilterForAssistant>({})

  const { dashboardAppointmentsPath, dashboardSurveysPath } =
    useDashboardPaths()

  const { pathname } = useLocation()
  const currentTab = last(pathname.split('/'))

  const tabs = [
    {
      label: t('menu:home.appointments'),
      value: DashboardTab.Appointments,
      to: DashboardTab.Appointments,
      component: Link,
    },
    {
      label: t('menu:home.surveys'),
      value: DashboardTab.Surveys,
      to: DashboardTab.Surveys,
      component: Link,
    },
  ]

  useEesztRedirectListener()

  return (
    <BetmenPage
      title={t('home:appointments.title')}
      tabs={tabs}
      selectedTabValue={currentTab}
      withoutPadding
      filters={
        <>
          {currentTab === DashboardTab.Surveys && (
            <DoctorSurveyFilters
              filterInput={filterInput}
              setFilterInput={setFilterInput}
              isFilterPanelOpen={isFilterPanelOpen}
              setFilterPanelOpen={setFilterPanelOpen}
            />
          )}
        </>
      }
    >
      <Switch>
        <Route path={dashboardAppointmentsPath}>
          <DoctorAppointmentsOnDashboard />
        </Route>
        <Route path={dashboardSurveysPath}>
          <DoctorSurveysOnDashboard
            filterInput={filterInput}
            setFilterInput={setFilterInput}
            isFilterPanelOpen={isFilterPanelOpen}
            setFilterPanelOpen={setFilterPanelOpen}
          />
        </Route>
      </Switch>
    </BetmenPage>
  )
}
