import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'

import {
  doctorProfileForList,
  SurveyCategoryEnum,
  SurveySchemaFilter,
  SurveySchemaStatusEnum,
} from '../../../../../models/graphqlTypes'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { SurveySchemaFilterListFiltersForm } from '../../types/surveyFilter.types'
import { FILTER_PANEL_WIDTH } from '../../../../../config'
import { BnoCodeSelector } from '../../../../../common/components/selectors/BnoCodeSelector'
import { hasActiveFilter } from '../../../../../utils/hasActiveFilter'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '../../../../../common/components'
import { ResetOneFilterButton } from '../../../../../common/components/FilterPanelButtons/ResetOneFilterButton'
import { InstitutionSelector } from '../../../../../common/components/selectors/InstitutionSelector'
import { ProfessionSelector } from '../../../../../common/components/selectors/ProfessionSelector'
import { DoctorSelector } from '../../../../../common/components/selectors/DoctorSelector'
import { BooleanFilter } from '../../../../../common/components/Filters/BooleanFilter'
import { useMe } from '../../../../../common/hooks/useMe'
import { isEqual, omit } from 'lodash'
import { OpenFilterPanelButton } from '../../../../../common/components/FilterPanelButtons/OpenFilterPanelButton'

interface SurveyListFiltersProps {
  isFilterPanelOpen: boolean
  setFilterPanelOpen: Dispatch<SetStateAction<boolean>>
  filterInput: SurveySchemaFilter
  setFilterInput: Dispatch<SetStateAction<SurveySchemaFilter>>
}
export const SurveyListFilters: React.FC<
  React.PropsWithChildren<SurveyListFiltersProps>
> = ({
  isFilterPanelOpen,
  setFilterPanelOpen,
  filterInput,
  setFilterInput,
}) => {
  const { t } = useTranslation('survey')

  const { data: { me } = {} } = useMe()

  const formMethods = useForm<SurveySchemaFilterListFiltersForm>({
    defaultValues: {
      statuses: { [SurveySchemaStatusEnum.Active]: true },
      professions: [],
      bnoCode: [],
      createdBy: [],
      institutions: [],
      categories: {},
    },
  })

  const { watch, reset, control, setValue } = formMethods

  const clearFilters = useCallback(() => {
    setFilterInput({ statuses: [SurveySchemaStatusEnum.Active] })
    reset()
  }, [reset, setFilterInput])

  useEffect(() => {
    const { unsubscribe } = watch((value) =>
      setFilterInput((prevInput: SurveySchemaFilter) => {
        return {
          ...prevInput,
          statuses: Object.entries(value.statuses ?? {})
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, isChecked]) => isChecked)
            .map(([status]) => status as SurveySchemaStatusEnum),
          professionIds: value.professions?.map((item) => item?.id ?? ''),
          bnoCodeIds: value.bnoCode?.map((item) => item?.id ?? ''),
          categories: Object.entries(value.categories ?? {})
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, isChecked]) => isChecked)
            .map(([category]) => category as SurveyCategoryEnum),
          createdByIds: value.createdBy?.map((item) => item?.userId ?? ''),
          institutionIds:
            value.institutions?.map((item) => item?.id ?? '') ?? [],
        }
      })
    )
    return () => {
      unsubscribe()
      clearFilters()
    }
  }, [clearFilters, setFilterInput, watch])

  const createdByFilter = watch('createdBy')
  const isOwnFilterActive = !!createdByFilter.find(
    (owner) => owner.userId === me?.id
  )
  const shouldShowResetAllButton =
    hasActiveFilter(omit(filterInput, 'statuses')) ||
    (hasActiveFilter(filterInput.statuses ?? {}) &&
      !isEqual(filterInput.statuses, [SurveySchemaStatusEnum.Active]))

  return (
    <Collapse in={isFilterPanelOpen} orientation="horizontal">
      <FormProvider {...formMethods}>
        <Stack
          sx={{
            width: FILTER_PANEL_WIDTH,
            height: '100%',
            backgroundColor: 'white',
          }}
          py={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            pl={2}
            pr={1}
          >
            <OpenFilterPanelButton
              isFilterPanelOpen={isFilterPanelOpen}
              setFilterPanelOpen={setFilterPanelOpen}
              filterInput={filterInput}
            />

            {shouldShowResetAllButton && (
              <Button
                size="small"
                color="primary"
                variant="text"
                onClick={clearFilters}
                sx={{ marginLeft: 'auto', paddingX: '4px' }}
              >
                {t('patients:filters.reset')}
              </Button>
            )}
          </Stack>

          <Stack px={1} sx={{ gap: '16px' }}>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.status.title')}
                </Typography>
                {!isEqual(filterInput.statuses, [
                  SurveySchemaStatusEnum.Active,
                ]) && (
                  <ResetOneFilterButton
                    onClick={() =>
                      setValue('statuses', {
                        [SurveySchemaStatusEnum.Active]: true,
                      })
                    }
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  {Object.values(SurveySchemaStatusEnum).map((status) => (
                    <Controller
                      key={status}
                      render={({ field }) => (
                        <FormControlLabel
                          label={t(`status.${status.toLowerCase()}`)}
                          control={
                            <Checkbox
                              {...field}
                              checked={!!watch(`statuses.${status}`)}
                            />
                          }
                        />
                      )}
                      name={`statuses.${status}`}
                      control={control}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.profession.title')}
                </Typography>
                {(filterInput.professionIds?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => setValue('professions', [])}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <ProfessionSelector name="professions" />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.bno.title')}
                </Typography>
                {(filterInput.bnoCodeIds?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => {
                      setValue('bnoCode', [])
                    }}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <BnoCodeSelector name="bnoCode" />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">{t('surveys.category')}</Typography>
                {(filterInput.categories?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => {
                      setValue('categories', {})
                    }}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  {Object.values(SurveyCategoryEnum).map((category) => (
                    <Controller
                      key={category}
                      render={({ field }) => (
                        <FormControlLabel
                          label={t(`survey.type.${category.toLowerCase()}`)}
                          control={
                            <Checkbox
                              {...field}
                              checked={!!watch(`categories.${category}`)}
                            />
                          }
                        />
                      )}
                      name={`categories.${category}`}
                      control={control}
                    />
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.owner.title')}
                </Typography>
                {(filterInput.createdByIds?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => setValue('createdBy', [])}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <BooleanFilter
                  filterInput={isOwnFilterActive}
                  setFilterInput={() => {
                    if (isOwnFilterActive) {
                      setValue('createdBy', [])
                    } else {
                      if (me?.profile) {
                        setValue('createdBy', [
                          {
                            ...me?.profile,
                            stampNumber: '',
                            userId: me.id,
                          } as doctorProfileForList,
                        ])
                      }
                    }
                  }}
                  label={t('survey:question.filter.own')}
                  sx={{ mb: 2 }}
                />

                <DoctorSelector name="createdBy" />
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.institution.title')}
                </Typography>
                {(filterInput.institutionIds?.length ?? 0) > 0 && (
                  <ResetOneFilterButton
                    onClick={() => setValue('institutions', [])}
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <InstitutionSelector name="institutions" multiple={true} />
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </FormProvider>
    </Collapse>
  )
}
