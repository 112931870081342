import { Box, Checkbox, Stack, Typography } from '@mui/material'

import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { BetmenListFilter } from '../../../../common/components'
import {
  useColorPalette,
  useTranslation,
  useUserType,
} from '../../../../common/hooks'
import { UsePatientsListReturn } from '../../../../common/hooks/usePatientsList'
import {
  deleteDoctorsOwnPatients as BulkDismissResult,
  PatientFilter,
  UserType,
} from '../../../../models/graphqlTypes'
import { PatientListItemType } from '../types/patientList.types'
import { OpenFilterPanelButton } from '../../../../common/components/FilterPanelButtons/OpenFilterPanelButton'
import { SendMessageToPatientsButton } from './PatientListActions/SendMessageToPatientsButton'
import { SendSurveyToPatientsButton } from './PatientListActions/SendSurveyToPatientsButton'
import { DeletePatientsButton } from './PatientListActions/DeletePatientsButton'
import { AddTreatmentToPatientsButton } from './PatientListActions/AddTreatmentToPatientsButton'

export interface PatientListActionsProps
  extends Pick<UsePatientsListReturn, 'data' | 'refetch'> {
  filterInput: PatientFilter
  setFilterInput: (filter: PatientFilter) => void
  selectedPatients: PatientListItemType[]
  setSelectedPatients: (ids: PatientListItemType[]) => void
  isFilterPanelOpen: boolean
  setFilterPanelOpen: Dispatch<SetStateAction<boolean>>
  deletedPatients: BulkDismissResult | null
  setDeletedPatients: Dispatch<SetStateAction<BulkDismissResult | null>>
}

const PatientListActions: React.FC<
  React.PropsWithChildren<PatientListActionsProps>
> = ({
  data,
  refetch,
  filterInput,
  setFilterInput,
  selectedPatients,
  setSelectedPatients,
  deletedPatients,
  setDeletedPatients,
  ...props
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const userType = useUserType()
  const isDoctor = userType === UserType.Doctor

  const handleSearch = (searchInput: string) => {
    setFilterInput({
      ...filterInput,
      nameOrTAJ: searchInput,
    })
  }

  const showActions = useMemo(
    () => data.some((patient) => patient.isActive),
    [data]
  )

  const clearSelectedIds = () => setSelectedPatients([])

  const handleSelectAll = () => {
    const selectablePatientList = data.filter((patient) => patient.isActive)
    if (selectedPatients.length === 0) {
      setSelectedPatients(selectablePatientList)
    } else {
      clearSelectedIds()
    }
  }

  return (
    <>
      <Stack direction="row" alignItems="center" mb={1} spacing={1}>
        {!props.isFilterPanelOpen && (
          <OpenFilterPanelButton filterInput={filterInput} {...props} />
        )}
        <BetmenListFilter
          searchTerm={filterInput.nameOrTAJ ?? ''}
          searchPlaceholder={t('patients:searchPatient')}
          handleSearch={(searchTerm) => handleSearch(searchTerm ?? '')}
        />
      </Stack>

      {showActions && (
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          sx={{
            backgroundColor: colorPalette.common.white,
            borderRadius: 1,
          }}
          mb={1}
          px={2}
          gap={1}
          flexWrap={'wrap'}
        >
          <Box width={40}>
            <Checkbox
              checked={
                data.length > 0 && selectedPatients.length === data.length
              }
              indeterminate={
                selectedPatients.length > 0 &&
                selectedPatients.length !== data.length
              }
              onChange={handleSelectAll}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            />
          </Box>

          {selectedPatients.length > 0 && (
            <>
              <SendMessageToPatientsButton
                selectedPatients={selectedPatients}
                clearSelectedIds={clearSelectedIds}
              />

              {isDoctor && (
                <>
                  <SendSurveyToPatientsButton
                    selectedPatients={selectedPatients}
                    clearSelectedIds={clearSelectedIds}
                  />
                  <AddTreatmentToPatientsButton
                    selectedPatients={selectedPatients}
                    clearSelectedIds={clearSelectedIds}
                  />
                  <DeletePatientsButton
                    selectedPatients={selectedPatients}
                    clearSelectedIds={clearSelectedIds}
                    refetch={refetch}
                    deletedPatients={deletedPatients}
                    setDeletedPatients={setDeletedPatients}
                  />
                </>
              )}
            </>
          )}
          <Typography marginLeft="auto" variant="smallItalic">
            {t('patients:selectedPatients', {
              count: selectedPatients.length,
            })}
          </Typography>
        </Stack>
      )}
    </>
  )
}

export { PatientListActions }
